@media screen and (max-width: 600px) {
    .footer {
            display: flex;
        flex-direction: column;
    }
    .footer h6{
        margin-top: 0px;
        margin-bottom: 0px;
        height: min-content;
    }
}

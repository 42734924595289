@media screen and (max-width: 900px) {
    .home {
        width: 100%;
    }
}
@media screen and (min-width: 900px) {
    .home {
        width: 900px;

    }
}
@media screen and (min-width: 1070px) {
    .home {
        width: 1000px;
    }
}
@media screen and (min-width: 1250px) {
    .home {
        width: 1000px;
    }
}

@media screen and (min-width: 1480px) {
    .home {
        width: 1000px;
    }
}


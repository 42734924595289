.form-ok-slider .swiper {
    width: 100%;
    height: 100%;
}

.form-ok-slider .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23393939'%2F%3E%3C%2Fsvg%3E") !important;
}

.form-ok-slider .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23393939'%2F%3E%3C%2Fsvg%3E") !important;
}

.form-ok-slider .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.form-ok-slider .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-ok-slider .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.form-ok-slider .swiper-slide {
    background-size: cover;
    background-position: center;
}

.form-ok-slider swiper-slide {
    width: 25%;
    height: 100%;
    background-color: #313642;
}

.form-ok-slider .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
    background-color: #313642;
}

.form-ok-slider .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #313642;
}

.form-ok-slider .ribbon {
    position: absolute;
    right: -5px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px;
    text-align: right;
}
.form-ok-slider .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#FF4853 0%, #D3232E 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
}
.form-ok-slider .ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #D3232E;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #D3232E;
}
.form-ok-slider .ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #D3232E;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #D3232E;
}

.form-ok-slider .swiper-wrapper{
    padding-bottom: 40px;
}


.form-ok-slider span.swiper-pagination-bullet-active {
    background: #D3232E !important;
    opacity: 1;
}

.form-ok-slider span.swiper-pagination-bullet {
    background-color: #ffffff;
    opacity: 1;
}
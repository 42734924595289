.inCard {
    top: -15%;
    scale: 1.4;

    transition: .1s ;


}
.outCart {
    top: 5%;
    scale: 1;
    transition: .1s ease;

}
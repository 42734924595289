@media screen and (max-width: 900px) {
    .hideInMobile {
        display: none;
    }
}

@media screen and (min-width: 800px) {
    .sizeCaption{
        font-size: xxx-large;
    }
    .viewButton{
        font-size: xxx-large;
        width: 11em;
        height: 3.5em;
    }
}

